<template>
    <nav class="navbar navbar-expand-lg fixed-top" style="background-color: #F3F3F3;">
        <div class="container-fluid">
            <a class="navbar-brand" href="#">
                <img src="./../assets/abbr_transparent-removebg-preview.png" alt="Bootstrap" width="75" height="60"
                    @click="goHome()">
            </a>

            <div class="collapse navbar-collapse" id="navbarText" v-if="showNav">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <button class="btn btn-green" @click="pushTickets"><i class="bi bi-ticket"></i> Tickets</button>
                    </li>
                    <!-- <li class="nav-item">
                        <button class="btn btn-green" @click="pushClients"><i class="bi bi-person-rolodex"></i>
                            Clients</button>
                    </li> -->
                    <li class="nav-item">
                        <button class="btn btn-green" @click="pushMetrics"><i class="bi bi-bar-chart"></i>
                            Metrics</button>
                    </li>
                </ul>

            </div>

            <div class="d-flex justify-content-center align-items-center" id="navbarNav">
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item">
                        <button class="btn" style="cursor:default; background-color: #212529; color:#c6ddcc">
                            <strong>
                                {{ firstLetterUser }}
                            </strong>
                        </button>
                    </li>
                </ul>
            </div>

        </div>
    </nav>
</template>

<script>
import { getUserProfile } from '@/authentication';
// import getClient from '@/helperFunctions/getClient';
// import config from '@/config';
import msalConfig from '@/config/msalConfig';
export default {
    name: 'MainNavBar',
    data: () => ({
        firstLetterUser: "",
        showNav: false,
        client: {},
        companyname: "",
    }),
    props: {},
    computed: {},
    methods: {
        goHome() {
            window.location.href = `/`;
        },
        pushTickets() {
            this.$router.push(`/dashboard/tickets`)
        },
        pushClients() {
            this.$router.push(`/`)
        },
        pushMetrics() {
            // this.$router.push(`/dashboard/metrics`)
            this.$router.push(`/`)
        }
    },
    async created() {
        try {
            let thisUser = await getUserProfile();
            this.firstLetterUser = thisUser.name.charAt(0);
            if (msalConfig.authorizedTenants.includes(thisUser.tenantId)) {
                this.showNav = true
            }
        } catch (error) {
            console.log(error);
        }
    }
}
</script>

<style scoped>
.navbar {
    box-shadow: 0 2px 5px -3px #305539;
    height: 70px;
    /* Adjust the values as needed */
}

.navbar-brand img {
    display: inline-block;
    vertical-align: middle;
}

.navbar-toggler {
    border-color: rgba(255, 255, 255, 0.1);
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.btn-green {
    color: black;
    background: #F3F3F3;
    border-radius: 5px;
    box-shadow: 0 2px 5px -3px #26360e;
}
</style>