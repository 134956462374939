export default {
    serviceauthendpoint: `https://${process.env.VUE_APP_API_PREPENDER}helper-functions.azurewebsites.net/api/generate-token?code=W3xaZhw3VkUMR8uTAcSiaBTZcbzraxZcTtUuyIaB8JM49FJfF077PA==`,
    servicedashboardendpoint: `https://${process.env.VUE_APP_API_PREPENDER}msp-dashboard.azurewebsites.net/api/dashboard_data?code=VcS0tlmSt6DxzxOrTlV7HnXTa9Xp0yIxeb19wooJixz8AzFurPYOEw==`,
    servicemspchildrenendpoint: `https://${process.env.VUE_APP_API_PREPENDER}msp-dashboard.azurewebsites.net/api/children?code=oRYJlcOeIMSeV6_rPoobT7-r_GAqqERlXn8Plu_2Y70uAzFuvuvo4Q==`,
    serviceonboardedusersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}helper-functions.azurewebsites.net/api/onboard/users?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
    serviceoffboardedusersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}helper-functions.azurewebsites.net/api/termination/users?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
    servicearchive: {
        listcontainers: `https://${process.env.VUE_APP_API_PREPENDER}service-archive.azurewebsites.net/api/archive/list-containers?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
        deletecontainers: `https://${process.env.VUE_APP_API_PREPENDER}service-archive.azurewebsites.net/api/archive/container?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
        listblobs: `https://${process.env.VUE_APP_API_PREPENDER}service-archive.azurewebsites.net/api/archive/drive/list-items?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
        downloadblobs: `https://${process.env.VUE_APP_API_PREPENDER}service-archive.azurewebsites.net/api/archive/drive/download?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
        startdrivearchive: `https://${process.env.VUE_APP_API_PREPENDER}service-archive.azurewebsites.net/api/archive/drive/start?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
        startemailarchive: `https://${process.env.VUE_APP_API_PREPENDER}service-archive.azurewebsites.net/api/archive/email/start?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
        startrestorearchive: `https://${process.env.VUE_APP_API_PREPENDER}service-archive.azurewebsites.net/api/restore/email/start?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
        cancelarchive: `https://${process.env.VUE_APP_API_PREPENDER}service-archive.azurewebsites.net/api/archive/cancel?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
        archivestatus: `https://${process.env.VUE_APP_API_PREPENDER}service-archive.azurewebsites.net/api/archive/status?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
        archiveauth: `https://${process.env.VUE_APP_API_PREPENDER}service-archive.azurewebsites.net/api/archive/credentials?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
    },
    servicemanagecontractorsendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-contractors.azurewebsites.net/api/contractors/users?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,

    servicetenanttypeendpoint: `https://${process.env.VUE_APP_API_PREPENDER}helper-functions.azurewebsites.net/api/manage/environment?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,

    servicesystemaccounts: `https://${process.env.VUE_APP_API_PREPENDER}helper-functions.azurewebsites.net/api/whitelist/users?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,

    servicegoogle: {
        usersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-google.azurewebsites.net/api/google/users?code=FJPJ1O621YAcgrHLbILbiNFyWg7rZbAIklhjllB2_jBYAzFuAU69-A==`,
        filtersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-google.azurewebsites.net/api/google/filters?code=FJPJ1O621YAcgrHLbILbiNFyWg7rZbAIklhjllB2_jBYAzFuAU69-A==`,
        credsendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-google.azurewebsites.net/api/google/credentials?code=FJPJ1O621YAcgrHLbILbiNFyWg7rZbAIklhjllB2_jBYAzFuAU69-A==`,
        authendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-google.azurewebsites.net/api/google/auth?code=FJPJ1O621YAcgrHLbILbiNFyWg7rZbAIklhjllB2_jBYAzFuAU69-A==`,
    },

    serviceazure: {
        usersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-azure.azurewebsites.net/api/azure/users?code=tHttety9YKOIlSZTG0nnT6DtNxhoCWWmWe3hvqZUZZssnCu8EfrTag==`,
        filtersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-azure.azurewebsites.net/api/azure/filters?code=tHttety9YKOIlSZTG0nnT6DtNxhoCWWmWe3hvqZUZZssnCu8EfrTag==`,
        credsendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-azure.azurewebsites.net/api/azure/credentials?code=tHttety9YKOIlSZTG0nnT6DtNxhoCWWmWe3hvqZUZZssnCu8EfrTag==`,
        authendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-azure.azurewebsites.net/api/azure/auth?code=tHttety9YKOIlSZTG0nnT6DtNxhoCWWmWe3hvqZUZZssnCu8EfrTag==`,
    },

    servicesnipe: {
        usersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-snipe.azurewebsites.net/api/snipe/users?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
        assetsendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-snipe.azurewebsites.net/api/snipe/assets?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
        filtersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-snipe.azurewebsites.net/api/snipe/filters?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
        credsendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-snipe.azurewebsites.net/api/snipe/credentials?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
        authendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-snipe.azurewebsites.net/api/snipe/auth?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
    },

    authCreds: {
        company_id: process.env.VUE_APP_API_COMPANY_ID,
        client_id: process.env.VUE_APP_API_CLIENT_ID,
        client_secret: process.env.VUE_APP_API_SECRET
    },
    servicedomainsmanagersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}helper-functions.azurewebsites.net/api/domains-managers?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
    serviceonboarduserendpoint: `https://${process.env.VUE_APP_API_PREPENDER}msp-dashboard.azurewebsites.net/api/onboard?code=ICTVQdEzlbr1aOcBu_NlvqyDryK8MHF1jHScXJpANgaUAzFudyVTnA==`,
    serviceoffboarduserendpoint: `https://${process.env.VUE_APP_API_PREPENDER}msp-dashboard.azurewebsites.net/api/offboard?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
    servicebillableendpoint: `https://${process.env.VUE_APP_API_PREPENDER}msp-dashboard.azurewebsites.net/api/billable/users?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
    servicebillablereviewendpoint: `https://${process.env.VUE_APP_API_PREPENDER}msp-dashboard.azurewebsites.net/api/billable/users/review?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
    servicecontractsendpoint: `https://${process.env.VUE_APP_API_PREPENDER}msp-dashboard.azurewebsites.net/api/contracts?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
    servicesettingsendpoint: `https://${process.env.VUE_APP_API_PREPENDER}msp-dashboard.azurewebsites.net/api/settings?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
    servicetickets: `https://${process.env.VUE_APP_API_PREPENDER}service-atlassian.azurewebsites.net/api/atlassian/query?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,

    // TICKET PORTAL CREDS
    key: process.env.VUE_APP_API_SECRET,
    headerkey: process.env.VUE_APP_API_HEADER_KEY,
    servicegetticketsendpoint: `https://jira-aggregator.azurewebsites.net/api/list_issues?code=8v4Il1-OLyXSjls3k-xOSVQz4J6a20GMPrhd4_wLGmLhAzFuaYr-UQ==`

}